/*------------- #MAIN-HEADER --------------*/
html,body{
	height: 100%;
}
.empty-area{
	border: 2px dashed #bbc0d5;
	padding: 1em;
}
.points {
	font-size: 12px;
	margin-bottom: 30px;
}
.statistics-list-count li:last-child {
	margin-bottom: 0;
}
.statistics-list-count li {
	margin-bottom: 18px;
}