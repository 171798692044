


.ui.segment > .close.icon,
.ui.segment > .expand.icon,
.ui.segment > .compress.icon {
	cursor: pointer;
	position: absolute;
	margin: 0;
	top: .78575em;
	right: .5em;
	opacity: .7;
	-webkit-transition: opacity .1s ease;
	transition: opacity .1s ease;
}

/** Prevent problem on IOS **/
html.ios {
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

html.ios,
html.ios body {
	height: initial !important;
}

/** CSS **/

html {
	padding: 0 !important;
	/*overflow-x: hidden;*/
}

[v-cloak] {
	display: none !important;
}

/** Semantic Patch **/
.ui.modal .scrolling.content {
	max-height: calc(70vh);
	overflow: auto;
}

* {
	word-wrap: break-word !important;
}

body {
	overflow-x: hidden !important;
}

h1, .h1 {
	font-size: 3.5em;
}

h2, .h2 {
	font-size: 2.7em;
}

h3, .h3 {
	font-size: 2.3em;
}

h4, .h4 {
	font-size: 2em;
}

h5, .h5 {
	font-size: 1.7em;
}

h6, .h6 {
	font-size: 1.15em;
}

.unselectable {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.description, .info {
	color: #6e7179;
	letter-spacing: 1px;
	font-size: 15px;
}

.event.description {
	margin: auto;
	max-width: 500px;
}

.info-title {
	letter-spacing: 1px;
	font-size: 15px;
	font-weight: 600;
	color: #282C35;
	text-transform: uppercase !important;
}

/* Background */
.background-grey {
	background-color: #f7f8fa !important;
	background-clip: content-box;
}

.background-white {
	background-color: #fff !important;
	background-clip: content-box;
}

.fixed {
	position: fixed;
	z-index: 101;
	margin: 0em;
	width: 100%;
	top: 0;
}

.scrollable_horizontal_mobile {
	overflow-x: auto;
}

.scrollable_horizontal_mobile > * {
	min-width: 767px;
}

/* Position */
.vertical-align {
	margin: auto;
}

.vertical-align-2 {
	display: table;
	height: 100%;
}

.vertical-align-2 > * {
	display: table-cell !important;
	vertical-align: middle !important;
}

.floated-right {
	float: right;
}

.floated-left {
	float: left;
}

/* Input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.padding-all {
	padding: 1em 1em 1em 1em !important;
}

.padding-top {
	padding-top: 1em !important;
}

.padding-right {
	padding-right: 1em !important;
}

.padding-bottom {
	padding-bottom: 1em !important;
}

.padding-left {
	padding-left: 1em !important;
}

.padding-bottom-zero {
	padding-bottom: 0px !important
}

.padding-top-zero {
	padding-top: 0px !important
}

.padding-zero {
	padding: 0 0 0 0px !important
}

.margin-top {
	margin-top: 1em !important;
}
.margin-top-zero {
	margin-top: 0 !important;
}

.margin-top-2 {
	margin-top: 2em !important;
}

.margin-top-3 {
	margin-top: 3em !important;
}

.margin-top-4 {
	margin-top: 4em !important;
}

.margin-top-5 {
	margin-top: 5em !important;
}

.margin-top-negative {
	margin-top: -1em !important;
}

.margin-top-negative-5 {
	margin-top: -5em !important;
}

.margin-right {
	margin-right: 1em !important;
}

.margin-bottom {
	margin-bottom: 1em !important;
}

.margin-left {
	margin-left: 1em !important;
}

.margin-zero {
	margin: 0 0 0 0px !important
}

.margin-bottom-zero {
	margin-bottom: 0em !important;
}

/** Text Align **/
@media only screen and (max-width: 767px) {
	.s-text-align-center {
		text-align: center !important;
	}

	.s-text-align-right {
		text-align: right !important;
	}

	.s-text-align-left {
		text-align: left !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.m-text-align-center {
		text-align: center !important;
	}

	.m-text-align-right {
		text-align: right !important;
	}

	.m-text-align-left {
		text-align: left !important;
	}
}

@media only screen and (min-width: 992px) {
	.l-text-align-center {
		text-align: center !important;
	}

	.l-text-align-right {
		text-align: right !important;
	}

	.l-text-align-left {
		text-align: left !important;
	}
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-wait {
	cursor: wait;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.hidden {
	display: none !important;
}

/* X Line for text */
@media only screen and (max-width: 767px) {
	.two-line-mobile {
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.two-line-tablet {
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
	}
}

@media only screen and (min-width: 992px) {
	.for-line-computer {
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
	}
}

.oneLine {
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}

.twoLine {
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}

/** WIDTH and DISPLAY**/
/*Computer*/
@media only screen and (min-width: 992px) {
	.tablet-only {
		display: none !important;
	}

	.mobile-only {
		display: none !important;
	}

	.mobile-only.computer-only {
		display: block !important;
	}

	.tablet-only.tablet-only {
		display: block !important;
	}

	.mobile-only.tablet-only.computer-only {
		display: block !important;
	}

	.computer-hidden {
		display: none !important;
	}

	.computer-fixed {
		position: fixed;
		z-index: 101;
		margin: 0;
		width: 100%;
	}

	.computer-fixed {
		position: fixed;
		z-index: 101;
		margin: 0;
		width: 100%;
	}

	.computer-hidden {
		display: none !important;
	}
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.computer-only {
		display: none !important;
	}

	.mobile-only {
		display: none !important;
	}

	.mobile-only.tablet-only {
		display: block !important;
	}

	.tablet-only.computer-only {
		display: block !important;
	}

	.mobile-only.tablet-only.computer-only {
		display: block !important;
	}

	.tablet-fixed {
		position: fixed;
		z-index: 101;
		margin: 0;
		width: 100%;
	}

	.tablet-hidden {
		display: none !important;
	}
}

/*Mobile*/
@media only screen and (max-width: 767px) {
	.computer-only {
		display: none !important;
	}

	.tablet-only {
		display: none !important;
	}

	.mobile-only.tablet-only {
		display: block !important;
	}

	.mobile-only.computer-only {
		display: block !important;
	}

	.mobile-only.tablet-only.computer-only {
		display: block !important;
	}

	.mobile-fixed {
		position: fixed;
		z-index: 101;
		margin: 0;
		width: 100%;
	}

	.mobile-hidden {
		display: none !important;
	}

	.bottom-fixed-mobile {
		bottom: 0;
		position: fixed;
		z-index: 101;
		margin: 0;
		width: 100%;
	}


	.ui.table.responsive thead {
		display:none;
	}

	.ui.table.responsive tr,.ui.table.responsive tfoot tr {
		display: block;
	}

	.ui.table.responsive td,.ui.table.responsive tfoot th {
		display: block;
		text-align: right;
	}
	.ui.table.responsive td::before,.ui.table.responsive tfoot th::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
	}
}

/** Fluid **/
.fluid {
	width: 100%;
}

/** Text Align **/
@media (max-width: 575px) {
	.xs-fluid {
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.s-fluid {
		width: 100%;
		max-width: 100%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.m-fluid {
		width: 100%;
	}
}

@media only screen and (min-width: 992px) {
	.l-fluid {
		width: 100%;
	}
}

/* Container */
@media only screen and (max-width: 767px) {
	.ui.main.container {
		padding-left: 0em !important;
		padding-right: 0em !important;
	}

	.ui.container.s-fluid {
		padding-left: 0em !important;
		padding-right: 0em !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

@media only screen and (min-width: 768px) {
	.ui.main.container {
		padding-left: 1em !important;
		padding-right: 1em !important;
	}
}

.ui.main.container {
	max-width: 860px !important;
}

.ui.main.large.container {
	max-width: 100% !important;
}

/** STRUCTURE **/
.gaia-dashboard-sidebar-left {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;
}

@media only screen and (min-width: 992px) {
	.gaia-dashboard-sidebar-left article {
		margin-left: 70px;
	}
}

table.equal-column {
	table-layout: fixed;
	word-wrap: break-word;
}
td.min-width{
	width: 1%;
	white-space: nowrap;
}


/* ÉLÉMENT */
.gaia-toggle {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.26);
}

.gaia-toggle input[type=checkbox] {
	opacity: 0;
	width: 0;
	height: 0;
	display: none;
}

.gaia-toggle input[type=checkbox] + .toggle {
	content: "";
	display: inline-block;
	width: 66px;
	height: 30px;
	background-color: rgba(80, 80, 80, 0.7);
	border-radius: 15px;
	margin-right: 0;
	transition: background 0.3s ease;
	vertical-align: middle;
	text-align: left;
	margin-left: 5px;
	cursor: pointer;
}

.gaia-toggle input[type=checkbox]:checked + .toggle {
	background-color: #2185d0;
}

.gaia-toggle input[type=checkbox]:checked + .toggle:after {
	left: 37px;
}

.gaia-toggle input[type=checkbox] + .toggle:after {
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 26px;
	background-color: #FFFFFF;
	border-radius: 20px;
	position: relative;
	left: 3px;
	top: 3px;
	transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
	text-align: center;
}

/* Button */
.ui.checked.button:after {
	position: absolute;
	font-family: FontAwesome;
	content: "\f00c";
	top: 50%;
	left: 50%;
	margin: -.64285714em 0 0 -.64285714em;
	width: 1.28571429em;
	height: 1.28571429em;
	color: #fff;

}

.ui.checked.button {
	position: relative;
	cursor: default;
	text-shadow: none !important;
	color: transparent !important;
	opacity: 1;
	pointer-events: auto;
	-webkit-transition: all 0s linear, opacity .1s ease;
	transition: all 0s linear, opacity .1s ease;
}

.ui.fail.button:after {
	position: absolute;
	font-family: FontAwesome;
	content: "\f00d";
	top: 50%;
	left: 50%;
	margin: -.64285714em 0 0 -.64285714em;
	width: 1.28571429em;
	height: 1.28571429em;
	color: #fff;

}

.ui.fail.button {
	position: relative;
	cursor: default;
	text-shadow: none !important;
	color: transparent !important;
	opacity: 1;
	pointer-events: auto;
	-webkit-transition: all 0s linear, opacity .1s ease;
	transition: all 0s linear, opacity .1s ease;
}

/* Events */

.masthead h1.ui.header {
	font-size: 4em;
	font-weight: normal;
}

.masthead {
	padding-top: 4em;
	padding-bottom: 4em;
	background-color: #5b5f68 !important;
}

/*upgrade semantic*/

.gaia-select {
	cursor: pointer;
	word-wrap: break-word;
	line-height: 1em;
	white-space: normal;
	outline: 0;
	-webkit-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	min-width: 14em;
	min-height: 2.71428571em;
	background: #FFFFFF;
	display: inline-block;
	/*padding: 0.78571429em 2.1em 0.78571429em 1em;*/
	padding-left: 0.5em;
	color: rgba(0, 0, 0, 0.87);
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid rgba(34, 36, 38, 0.15);
	border-radius: 0.28571429rem;
	-webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
	transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
	transition: box-shadow 0.1s ease, width 0.1s ease;
	transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.to-uppercase {
	text-transform: uppercase;
}

.to-lowercase {
	text-transform: lowercase;
}

.to-capitalize {
	text-transform: capitalize;
}

.min-height-100p {
	min-height: 100% !important;
}

.max-width-5p {
	max-width: 5% !important;
}

.max-width-10p {
	max-width: 10% !important;
}

.min-height-100vh {
	min-height: 100vh !important;
	height: 100%;
}

.ui.inverted.segment.purple-waves {
	background: url(../images/backgrounds/purple-wave.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.ui.white.button {
	color: #000;
	text-shadow: none;
	background: #FFF none;
}

.badge {
	font-size: 0.64285714rem;
	padding: .33em .5em;
	font-weight: 400;
	line-height: 1;
	display: inline-block;
	text-align: center;
	vertical-align: baseline;
	white-space: nowrap;
	border-radius: .375rem;
}

.badge.big {
	font-size: 1rem;
}

.badge.huge {
	font-size: 1.2rem;
}

.badge.success {
	color: #00d97e;
	background-color: #ccf7e5;
}

.badge.warning {
	color: #f6c343;
	background-color: #fdf3d9;
}

.badge.danger {
	color: #e63757;
	background-color: #fad7dd;
}

/* CSS SCAN */
.ocrloader {
	width: 94px;
	height: 77px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	backface-visibility: hidden;
}

.ocrloader span {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 20px;
	background-color: rgba(255, 0, 0, 0.54);
	z-index: 1;
	transform: translateY(135%);
	animation: move 1.2s cubic-bezier(0.15, 0.44, 0.76, 0.64);
	animation-iteration-count: infinite;
}

.ocrloader > div {
	z-index: 1;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 48%;
	backface-visibility: hidden;
}

.ocrloader i {
	display: block;
	height: 1px;
	background: #000;
	margin: 0 auto 2px;
	margin: 0 auto 2.2px;
	backface-visibility: hidden;
}

.ocrloader i:nth-child(2) {
	width: 75%;
}

.ocrloader i:nth-child(3) {
	width: 81%;
}

.ocrloader i:nth-child(4) {
	width: 87%;
}

.ocrloader i:nth-child(6) {
	width: 71%;
}

.ocrloader i:nth-child(7) {
	width: 81%;
}

.ocrloader i:nth-child(8) {
	width: 65%;
}

.ocrloader i:nth-child(9) {
	width: 83%;
}

.ocrloader i:nth-child(10) {
	width: 75%;
}

.ocrloader i:nth-child(12) {
	width: 86%;
}

.ocrloader i:nth-child(14) {
	width: 65%;
}

.ocrloader i:nth-child(16) {
	width: 75%;
}

.ocrloader i:nth-child(18) {
	width: 83%;
}

.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
	border-color: #000;
	content: "";
	position: absolute;
	width: 19px;
	height: 16px;
	border-style: solid;
	border-width: 0px;
}

.ocrloader:before {
	left: 0;
	top: 0;
	border-left-width: 1px;
	border-top-width: 1px;
}

.ocrloader:after {
	right: 0;
	top: 0;
	border-right-width: 1px;
	border-top-width: 1px;
}

.ocrloader em:before {
	left: 0;
	bottom: 0;
	border-left-width: 1px;
	border-bottom-width: 1px;
}

.ocrloader em:after {
	right: 0;
	bottom: 0;
	border-right-width: 1px;
	border-bottom-width: 1px;
}

@keyframes move {
	0%,
	100% {
		transform: translateY(135%);
	}
	50% {
		transform: translateY(0%);
	}
	75% {
		transform: translateY(272%);
	}
}

.field > label, label {
	font-weight: bold;
}

label.required:after {
	margin: -.2em 0 0 .2em;
	content: '*';
	color: #db2828;
}

/** Hover **/
.shadow-hover:hover {
	box-shadow: rgba(15, 15, 15, 0.12) 0px 8px 16px 0px !important;
}

.ui.rounded.button, .ui.rounded.label {
	border-radius: 0.625rem !important;
}
.ui.top.attached.rounded.label {
	border-radius: 0.625rem 0.625rem 0 0!important;
}
.ui.bottom.attached.rounded.label {
	border-radius: 0 0 0.625rem 0.625rem!important;
}

.unrounded {
	border-radius: 0 !important;
}

.sessions {
	margin-top: 2rem;
	border-radius: 12px;
	position: relative;
}

.sessions li {
	padding-bottom: 1.5rem;
	border-left: 1px solid #2185D0;
	position: relative;
	padding-left: 20px;
	margin-left: 10px;
	list-style: none;
}
.sessions li:last-child {
	border: 0;
	padding-bottom: 0;
}
.sessions li:before {
	content: "";
	width: 15px;
	height: 15px;
	background: white;
	border: 1px solid #2185D0;
	border-radius: 50%;
	position: absolute;
	left: -8px;
	top: 0;
}

.sessions .time {
	color: #2a2839;
	font-weight: 500;
}

.striped-bg {
	background: repeating-linear-gradient(
		45deg,
        #d6f2ff,
		#d6f2ff 10px,
		#b3e7ff 10px,
		#b3e7ff 20px
	);
	border: 1px solid #7e8181 !important;
}

.selected-bg {
	border: 3px solid #2185D0 !important;
}

#inline_calendar .active {
	box-shadow: 0 0 0 3px #2185D0 inset !important;
}

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	background: #FAFAFA !important;
}

body {
	display: flex;
}

#page {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.main-scrolling-content {
	overflow-y: auto; height: 100%;
	padding-bottom: 60px;
}
@media screen and (max-width: 900px) {
	.main-scrolling-content {
		padding-top: 60px;
	}
}

.side-menu {
	height: 100%;
	width: 280px;
	background: white;
	border-right: #E2E2E2 1px solid;
	padding: 47px 35px;
	font-family: roboto, sans-serif;
	box-sizing: border-box;
	overflow-y: scroll;
	transition: width .5s cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (min-width: 900px) {
	.side-menu.minimized:not(:hover) {
		padding: 47px 20px;
		width: 85px;
	}

	.side-menu.minimized:not(:hover) .menu {
		align-items: center;
	}


	.side-menu.minimized:not(:hover) .establishment .informations, .side-menu.minimized:not(:hover) .establishment .switch {
		display: none !important;
	}

	.side-menu.minimized:not(:hover) .menu .item span {
		width: 0;
		overflow: hidden;
		margin: 0;
	}

	.side-menu.minimized:not(:hover) .menu .head {
		width: 0; overflow: hidden;
	}
}




@media screen and (max-width: 900px) {
	.side-menu {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
        z-index: 120;
		transform: translateX(-100%);
		transition: transform .5s cubic-bezier(0.22, 1, 0.36, 1);
	}

	#sidebar.opened .side-menu {
		transform: translateX(0);
	}

	#sidebar.opened .dimmer {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 5;
	}
}

.side-menu *, .side-menu *:hover {
	text-decoration: none;
	color: black;
}

.side-menu::-webkit-scrollbar {
	width: 3px;
}

.side-menu::-webkit-scrollbar-track {
	background: none;
}
.side-menu::-webkit-scrollbar-thumb {
	background-color: #E2E2E2;
	border-radius: 20px;
}
.side-menu .establishment, #top-bar .establishment {
	display: flex;
	align-items: center;
	margin-bottom: 60px;
}

#top-bar .establishment {
	margin: 0;
}

.side-menu .establishment .icon, #top-bar .establishment .icon {
	height: 48px;
	width: 48px;
	background: #00aff0;
	border-radius: 10px;
}

.side-menu .establishment .informations, #top-bar .establishment .informations {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	color: #A6A6A6;
	margin-left: 11px;
	flex-wrap: nowrap;
	overflow: hidden;
	white-space: nowrap;
}

.side-menu .establishment .informations .header, #top-bar .establishment .informations .header {
	font-size: 17px;
	font-weight: bold;
	color: #6D6D6D;
	margin-bottom: 4px;
}

#top-bar .establishment .informations .header {
	margin: 0;
}

.side-menu .menu {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}



.side-menu .menu .item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	cursor: pointer;
	opacity: 0.4;
}

.side-menu .menu .item.active {
	opacity: 1;
}

.side-menu .menu .item i {
	font-size: 22px;
	width: 25px;
	text-align: center;
}

.side-menu .menu .item .icon {
	height: 24px;
	width: 24px;
	border: 1px solid black;
	border-radius: 10px;
}

.side-menu .menu .item span {
	margin-left: 16px;
	font-size: 14px;
	font-weight: 600;
	word-break: keep-all;
	white-space: nowrap;
}

.side-menu .menu .head .separator {
	width: 100%;
	border: 1px solid #E2E2E2;
	margin-bottom: 21px;
}

.side-menu .menu .head .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.side-menu .menu .head .header h3 {
	font-size: 15px;
	font-weight: bold;
	margin: 0;
}


.switch-establishment {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 18px 0;
	margin: 2px 0;
}

.switch-establishment.current {
	opacity: 0.6;
}

.switch-establishment > div {
	display: flex;
	align-items: center;
}

.switch-establishment .informations {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 11px;
	font-size: 14px;
	color: #A6A6A6;
}

.switch-establishment .informations .header {
	font-size: 17px;
	font-weight: bold;
	color: #6D6D6D;
	margin-bottom: 2px;
}

.switch-establishment .switch-icon {
	position: absolute;
	right: 0;
	background: white;
	width: 40px;
	height: 118%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: width 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.switch-establishment .switch-icon.selected {
	width: 100%;
}

@media screen and (min-width: 900px) {
	#switch-establishment-drawer {
		display: none;
	}
}

@media screen and (max-width: 900px) {
	#switch-establishment-modal {
		display: none;
	}
}

* {
	--fa-primary-color: red;
	--fa-secondary-color: red;
}


#top-bar {
	width: 100%;
	height: 60px;
	position: absolute;
	top: 0;
	z-index: 100;
	background: rgba(255, 255, 255, 0.85);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	padding: 16px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media screen and (min-width: 900px) {
	#top-bar {
		display: none;
	}
}

#page .right-panel {
	overflow-y: auto;
	height: 100%;
	padding: 20px;
	max-width: 375px;
	width: 375px;
	background: white;
	flex-shrink: 0;
	flex-grow: 2;
	border-left: #eeeeee 1px solid;
}

@media screen and (max-width: 1400px) {
	#page .right-panel {
		display: none;
	}
}

.page-header {
	display: flex;
	align-items: center;
}

.page-header > .icon {
	display: flex;
	height: 45px;
	width: 45px;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.39);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	border-radius: 8px;
	flex-shrink: 0;
}

.page-header .icon i {
	font-size: 28px;
	color: white;
	margin-top: 3px;
}

.page-header .title {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	font-size: 14px;
}

.page-header .title h4 {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 0;
}






.main-content-right-panel {
	width: 450px;
	max-width: 450px;
	overflow-x: hidden;
	background: white;
	overflow-y: auto;
	height: 100%;
	padding: 20px;
	flex-shrink: 0;
	flex-grow: 2;
	border-left: #eeeeee 1px solid;
}

.main-content-right-panel .mobile-return {
	display: none;
	cursor: pointer;
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
	.main-content-right-panel {
		transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
		transform: translateX(100%);
		position: absolute;
		top: 0; bottom: 0; right: 0;
		display: initial;
		width: 400px;
		flex-grow: 0;
		z-index: 1000;
	}

	.main-content-right-panel.opened {
		transform: none;
	}

	.right-panel-dimmer.opened {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(6px);
		z-index: 999;
	}

	.main-content-right-panel .mobile-return {
		display: initial;
	}
}

@media screen and (max-width: 900px) {
	.main-content-right-panel {
		display: initial;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 4;
		background: white;
		height: 100%;
		width: 100%;
		max-width: 100%;
		padding: 80px 24px 24px 24px;
		transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
		transform: translateX(100%);
		overflow-y: auto;
	}

	.main-content-right-panel.opened {
		transform: none;
	}

	.main-content-right-panel .mobile-return {
		display: initial;
	}
}


.font-awesome-icon {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	line-height: 1;
	border-radius: 0;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
	text-align: center;
	-webkit-animation: none;
	animation: none;
	padding: 0.78571429em 0 0.78571429em 0;
	margin: 0;
	width: 2.57142857em;
	background-color: rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: -1px 0 0 0 transparent inset;
	box-shadow: -1px 0 0 0 transparent inset;
}