.hidden {
    display: none !important;
}

/** Text **/
.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

/** Cursor **/
.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

/** Background **/
.background-white {
    background-color: white !important;
}

.background-transparent {
    background-color: #ffffff00 !important;
}

/** Border **/
.border-transparent {
    border: 0 !important;
    box-shadow: 0 0 0 0 !important;
}


/** Width **/
.max-width-5p {
    max-width: 5% !important;
}

.max-width-10p {
    max-width: 10% !important;
}

.max-width-20p {
    max-width: 20% !important;
}

.max-width-30p {
    max-width: 30% !important;
}

.max-width-40p {
    max-width: 40% !important;
}

.max-width-100p {
    max-width: 100% !important;
}

.width-5p {
    max-width: 5% !important;
    min-width: 5% !important;
}

.width-10p {
    max-width: 10% !important;
    min-width: 10% !important;
}

.width-20p {
    max-width: 20% !important;
    min-width: 20% !important;
}

/* Position */
.vertical-align {
    margin: auto !important;
}

.margin-auto {
    margin: auto !important;
}

.margin-zero {
    margin: 0 !important;
}

.margin-top-1 {
    margin-top: 1rem !important;
}

.margin-bottom-1 {
    margin-bottom: 1rem !important;
}

*
.padding-zero {
    padding: 0 !important;
}

/** Hover **/
.shadow-hover:hover {
    box-shadow: rgba(15, 15, 15, 0.12) 0 8px 16px 0 !important;
}

/** Segment&button test**/
.ui.rounded.segment, .ui.rounded.segments, .ui.rounded.input > input {
    border-radius: 0.75rem !important;
}

.ui.rounded.segments > .ui.segment:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.ui.rounded.segments > .ui.segment:last-child {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}

.ui.rounded.horizontal.segments > .ui.segment:first-child {
    border-radius: 0.625rem 0 0 0.625rem;
}

.ui.rounded.horizontal.segments > .ui.segment:last-child {
    border-radius: 0 0.625rem 0.625rem 0;
}

.ui.rounded.button, .ui.rounded.label {
    border-radius: 0.625rem !important;
}

.ui.top.attached.rounded.label {
    border-radius: 0.625rem 0.625rem 0 0 !important;
}

.ui.bottom.attached.rounded.label {
    border-radius: 0 0 0.625rem 0.625rem !important;
}

.unrounded {
    border-radius: 0 !important;
}

/** WIDTH and DISPLAY**/

/*Computer*/
@media only screen and (min-width: 992px) {
    .tablet-only {
        display: none !important;
    }

    .mobile-only {
        display: none !important;
    }

    .mobile-only.computer-only {
        display: block !important;
    }

    .tablet-only.tablet-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .computer-hidden {
        display: none !important;
    }

    .computer-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .computer-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .computer-hidden {
        display: none !important;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .computer-only {
        display: none !important;
    }

    .mobile-only {
        display: none !important;
    }

    .mobile-only.tablet-only {
        display: block !important;
    }

    .tablet-only.computer-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .tablet-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .tablet-hidden {
        display: none !important;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .computer-only {
        display: none !important;
    }

    .tablet-only {
        display: none !important;
    }

    .mobile-only.tablet-only {
        display: block !important;
    }

    .mobile-only.computer-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .mobile-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .mobile-hidden {
        display: none !important;
    }

    .bottom-fixed-mobile {
        bottom: 0;
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }
}

/* Confidential */
.confidential {
    filter: blur(10px);
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.discreet {
    opacity: 0.45 !important;
}

/** Input **/
.ui.left.labeled.input > .ui.search:last-child > .ui.input > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/** Segment **/
.segment.spaced, .ui.horizontal.spaced.segments {
    margin-top: 20px;
    border-top: none !important;
    border-radius: 10px !important;
    box-shadow: 1px 1px 1px 0 rgba(34, 36, 38, 0.1) !important;
}

.ui.horizontal.spaced.segments > .ui.spaced.segment {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;
}

.ui.horizontal.spaced.segments > .ui.spaced.segment.control {
    border-left: solid 2px #f9f9f9 !important;
}

.ui.horizontal.spaced.segments > .ui.spaced.segment.select {
    border-right: solid 2px #f9f9f9 !important;
}

.ui.horizontal.spaced.segments > .ui.spaced.segment.select,
.ui.horizontal.spaced.segments > .ui.spaced.segment.control {
    flex: inherit;
    cursor: pointer;
}

.ui.horizontal.spaced.segments > .ui.spaced.segment.control > *,
.ui.horizontal.spaced.segments > .ui.spaced.segment.select > * {
    height: 100%;
    display: flex;
}

.ui.segment > table {
    width: 100%;
}

.ui.spaced.basic.segments {
    background-color: transparent;
    box-shadow: none !important;
    border: none;
}


/** Menu **/
.ui.inverted.menu .blue.active.item,
.ui.inverted.blue.menu {
    background-color: #4696e6;
}

.ui.inverted.blue.menu .item:before {
    background-color: transparent !important;
}
.word-break-all{
    word-break: break-all!important;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}