.bottom-drawer > .background {
    position: fixed;
    z-index: 300;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}

.bottom-drawer > .drawer-container {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    max-height: 70%;
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    margin: 0;
    padding: 0;
    border: none;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-y: auto;
    transform: translateY(100%);
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);*/
}

.bottom-drawer > .drawer-container > h3 {
    margin-top: 0 !important;
}

.bottom-drawer.opened > .background {
    display: initial;
}

.bottom-drawer.opened > .drawer-container {
    padding: 30px 20px 30px 20px;
    transform: translateY(0);
}

.bottom-drawer.opened.no-padding > .drawer-container {
    max-height: 70%;
    padding: 0;
}


.bottom-drawer > .drawer-container .close-button {
    position: absolute;
    top: 5px;
    right: 0;
}
