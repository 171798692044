/*Computer*/
@media only screen and (min-width: 992px) {
   .l-right.menu{
       display: flex;
        margin-left: auto !important;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .m-right.menu{
        display: flex;
        margin-left: auto !important;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .s-right.menu{
        display: flex;
        margin-left: auto !important;
    }
}